<template>
  <el-card shadow="never">
    <div class="card-header">
      <el-page-header @back="goBack">
        <template #content>
          <span class="text-large font-600 mr-3"> 编辑会员 </span>
        </template>
      </el-page-header>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-form :model="formData" label-width="120px">
      <el-divider content-position="left">基本资料</el-divider>
      <el-form inline label-width="120px">
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action=""
            :http-request="uploadAvatar"
            :show-file-list="false"
          >
            <img v-if="formData.avatar" :src="formData.avatar" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item label="联系二维码">
          <el-upload
            class="avatar-uploader"
            action=""
            :http-request="uploadFile"
            :show-file-list="false"
          >
            <img v-if="formData.file" :src="formData.file" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form inline label-width="120px">
        <el-form-item label="昵称">
          <el-input v-model="formData.nickname" />
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="formData.realname" />
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="formData.mobile" />
        </el-form-item>
      </el-form>
      <el-form-item label="班委">
        <el-select v-model="formData.banwei" class="m-2" placeholder="请选择班委" size="large">
          <el-option
            label="无"
            value="0"
          />
          <el-option
            v-for="item in bwlist"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="军团">
        <el-select v-model="formData.juntuan" class="m-2" placeholder="请选择军团" size="large">
          <el-option
            label="无"
            value="0"
          />
          <el-option
            v-for="item in jtlist"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="军团职位">
        <el-select v-model="formData.level" class="m-2" placeholder="请选择军团职位" size="large">
          <el-option
            label="无"
            value="0"
          />
          <el-option
            v-for="item in levellist"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-divider content-position="left">公司资料</el-divider>
      <el-form-item label="公司名称">
        <el-input v-model="formData.company" />
      </el-form-item>
      <el-form-item label="网址">
          <el-input v-model="formData.url" />
        </el-form-item>
      <el-form inline label-width="120px">
        <el-form-item label="职位">
          <el-input v-model="formData.position" />
        </el-form-item>
        <!-- <el-form-item label="公司电话">
          <el-input v-model="formData.phone" />
        </el-form-item> -->
        <el-form-item label="邮箱">
          <el-input v-model="formData.email" />
        </el-form-item>
        <el-form-item label="行业">
          <el-select v-model="formData.industry" class="m-2" placeholder="请选择公司行业" size="large">
            <el-option
              v-for="item in industrylist"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-form-item label="公司地址">
        <el-cascader v-model="area" :options="cityData" @change="handleAreaChange" />
      </el-form-item>
      <el-form-item label=" ">
        <el-input v-model="formData.address" />
      </el-form-item>
      <el-row>
        <el-divider content-position="left">公司业务</el-divider>
        <el-form :model="formData" label-width="120px">
          <el-form-item label="内容">
            <el-input
              v-model="formData.content"
              :autosize="{ minRows: 4, maxRows: 10 }"
              type="textarea"
              placeholder=""
              style="width:600px;"
            />
          </el-form-item>
          <el-form-item label=" ">
            <el-upload
              v-model:file-list="formData.business"
              action=""
              list-type="picture-card"
              multiple
              :http-request="uploadBusinessList"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleBusinessRemove"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row>
        <el-divider content-position="left">生活圈</el-divider>
        <el-form-item label=" ">
          <el-upload
            v-model:file-list="formData.lifecircle"
            action=""
            list-type="picture-card"
            multiple
            :http-request="uploadLifecircleList"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleLifecircleRemove"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-row>
      <el-form :model="formData" label-width="120px" v-if="formData.status===0">
        <el-form-item label="审核状态">
          <el-radio-group v-model="examineData.status" class="ml-4">
            <el-radio label="1" size="large">通过</el-radio>
            <el-radio label="2" size="large">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="原因">
          <el-input v-model="examineData.reason" autocomplete="off" show-word-limit maxlength="50"/>
        </el-form-item>
        <el-form-item>
          <el-button @click="setexamine">审核</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
      <el-form :model="formData" label-width="120px" v-else>
        <el-form-item label="原因">
          {{examineData.reason}}
        </el-form-item>
        <el-form-item label="审核状态">
          {{examineData.status==1 ? '通过':'不通过'}}
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </el-form>
    <el-dialog v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </el-card>
</template>

<script>
  import { ElMessage } from 'element-plus'
  import api from '@/api/index.js'
  import { defineComponent, ref } from 'vue'
  import cityData from "@/utils/citydata.js"
  export default defineComponent({
    setup() {
      const formData = ref({
        id:'',
        title:'',
        type:'1',
        timeType:'1',
        imglist:[],
        releaseTime:'',
        startTime:'',
        endTime:'',
        content:'',
      })
      let examineData = ref({
        reason:'',
        id:'',
        status:"1",
      })
      const area = ref([])
      const levellist = ref([])
      const jtlist = ref([])
      const bwlist = ref([])
      const industrylist = ref([])
      const cityData = ref([])
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)
      return {
        formData,
        levellist,
        jtlist,
        bwlist,
        industrylist,
        examineData,
        cityData,
        dialogVisible,
        dialogImageUrl,
        area,
      }
    },
    methods: {
      handleAreaChange(e){
        let citylist = [],arealist = []
        for(var i in this.cityData){
          if(this.cityData[i].value==e[0]){
            this.formData.provinceName = this.cityData[i].label
            this.formData.province = this.cityData[i].value
            citylist = this.cityData[i].children
            for(var j in citylist){
              if(citylist[j].value==e[1]){
                this.formData.cityName = citylist[j].label
                this.formData.city = citylist[j].value
                arealist = citylist[j].children
                for(var m in arealist){
                  this.formData.areaName = arealist[m].label
                  this.formData.area = arealist[m].value
                }
              }
            }
          }
        }
      },
      handlePictureCardPreview(file){
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      handleBusinessRemove(file){
        for(var i in this.formData.business){
          if(file.url == this.formData.business[i].url){
            this.formData.business.splice(i,1)
          }
        }
      },
      handleLifecircleRemove(file){
        for(var i in this.formData.lifecircle){
          if(file.url == this.formData.lifecircle[i].url){
            this.formData.lifecircle.splice(i,1)
          }
        }
      },
      uploadFile(params){
				const file = params.file,form = new FormData();
				form.append("file", file)
				api.shopApi.uploadImg(form).then(res=>{
					this.formData.file=res
				})
			},
      uploadAvatar(params){
				const file = params.file,form = new FormData();
				form.append("file", file)
				api.shopApi.uploadImg(form).then(res=>{
					this.formData.avatar=res
				})
			},
      uploadBusinessList(params){
				const file = params.file,form = new FormData();
				form.append("file", file)
				api.shopApi.uploadImg(form).then(res=>{
          this.formData.business[this.formData.business.length-1] = {url:res,name:res}
				})
			},
      uploadLifecircleList(params){
				const file = params.file,form = new FormData();
				form.append("file", file)
				api.shopApi.uploadImg(form).then(res=>{
          this.formData.lifecircle[this.formData.lifecircle.length-1] = {url:res,name:res}
				})
			},
      save(){
        let that = this
        api.memberApi.save(that.formData).then(res => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.goBack()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      setexamine(){
        let that = this
        api.memberApi.setexamine(that.examineData).then(res => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.goBack()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      goBack(){
        this.$router.go(-1)
      },
      getLevelList(){
        let that = this
        api.memberApi.getAllLevel().then(res => {
            if (res.code === 0) {
              that.levellist = res.data
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      getJtList(){
        let that = this
        api.memberApi.getAllJt().then(res => {
            if (res.code === 0) {
              that.jtlist = res.data
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      getBwList(){
        let that = this
        api.memberApi.getAllBw().then(res => {
            if (res.code === 0) {
              that.bwlist = res.data
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      getIndustryList(){
        let that = this
        api.memberApi.getAllIndustry().then(res => {
            if (res.code === 0) {
              that.industrylist = res.data
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      get(){
        let that = this
        api.memberApi.get({id:that.formData.id}).then(res => {
            if (res.code === 0) {
              that.formData=res.data
              that.area = [res.data.province.toString(),res.data.city.toString(),res.data.area.toString()]
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      saveCulture(){
        let that = this
        api.cultureApi.set(that.formData).then(res => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.goBack()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      }
    },
    mounted () {
      this.formData.id = this.$route.query.id
      this.examineData.id = this.$route.query.id
      this.cityData = cityData
      this.getLevelList()
      this.getBwList()
      this.getJtList()
      this.getIndustryList()
      this.get()
      
    }
  })
</script>
<style scoped>
  .avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  </style>
  
  <style>
  .avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }
  
  .avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
  }
  
  .el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
  }
  </style>